<template>
  <div>
    <my-header></my-header>
    <div>
      <div class="bgimg">
        <img src="../assets/images/certificateBG.jpeg" alt="" />
      </div>
      <div class="box">
        <div>
          <span>添加公司证书</span>
        </div>
        <div>
          <div>
            <div @mouseenter="show1 = true" @mouseleave="show1 = false">
              <span @click="$router.push('index')">首页</span>
              <transition name="el-zoom-in-center">
                <div v-show="show1" class="transition-box"></div>
              </transition>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div @mouseenter="show2 = true" @mouseleave="show2 = false">
              <span @click="$router.go(-1)">返回</span>
              <transition name="el-zoom-in-center">
                <div v-show="show2" class="transition-box"></div>
              </transition>
            </div> 

            <el-divider direction="vertical"></el-divider>
          </div>
        </div>
        <div class="form">
          <el-form label-position="top" label-width="80px" :model="form">
            <el-form-item label="证书图片:">
              <el-upload
                action="http://www.jujiangmofang.cn/kitchenac/file/upload"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-success="handleSuccess"
                :on-remove="handleRemove"
                :on-error="handleError"
                :multiple="true"
                :drag="true"
                tip=""
                name="upload_file"
                ><div class="el-upload__text">
                  <i class="el-icon-upload"></i>

                  将文件拖到此处，或<em>点击上传</em>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
              <div class="fileListName">
                <div v-for="(v, k) of fileList" :key="k">
                  <span>修改证书名：</span><input type="text" v-model="v.name" />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="证书类型:">
              <el-radio-group v-model="form.type">
                <el-radio label="1">资质证书</el-radio>
                <el-radio label="2">荣誉证书</el-radio>
                <el-radio label="3">公司业绩</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">立即添加</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      fileList: [],
      show1: false,
      show2: false,
      dialogImageUrl: "",
      dialogVisible: false,
      form: {
        type: "",
      },
      submit: [],
    };
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.$message({
        message: "删除成功",
        type: "success",
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleError() {
      this.$message.error("图片上传失败，图片格式错误，请使用png,jpg格式重新上传");
    },
    handleSuccess(response, file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList);
      this.$message({
        message: "上传成功",
        type: "success",
      });
    },
    // 添加公司认证
    async onSubmit() {
      this.submit = [];
      this.fileList.map((v, k) => {
        this.submit[k] = {};
        this.submit[k].title = this.fileList[k].name;
        this.submit[k].type = this.form.type;
        this.submit[
          k
        ].certifyImg = `${this.fileList[k].response.data.url}${this.fileList[k].response.data.uri}`;
      });
      console.log(this.submit);
      const res = await this.$http({
        method: "post",
        url: `front/CompanyCertify/batchSave`,
        data: {
          imgJson: JSON.stringify(this.submit),
        },
      });
      if (res.data.status == 200) {
        this.$message({
          message: "添加成功",
          type: "success",
        });
        this.reload();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bgimg {
  width: 100%;
  margin-top: 160px;
  img {
    width: 100%;
  }
}
.box {
  width: 90%;
  height: 1200px;
  background: #fff;
  position: relative;
  top: -160px;
  z-index: 15;
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  & > div:nth-child(1) {
    font-size: 40px;
    color: #004098;
    padding: 40px;
    padding-left: 70px;
    user-select: none;
  }
  & > div:nth-child(2) {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    div:nth-child(1) {
      display: flex;
      height: 60px;
      margin-left: 20px;
      .el-divider--vertical {
        margin-top: 4px;
      }
      div:hover span {
        color: #009fc9;
      }
      div {
        cursor: pointer;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .transition-box {
          background-color: #009fc9;
          width: 90px;
          height: 3px;
          margin-top: 30px;
        }
        span {
          width: 100px;
          color: #555;
        }
      }
    }
  }
  .form {
    width: 900px;
    min-width: 900px;
    margin: 20px auto;
    .fileListName {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      div {
        width: 146px;
        margin-right: 10px;
        input {
          width: 142px;
          border: 1px solid #aaa;
          outline: none;
        }
      }
    }
  }
}
</style>